import type { Partner } from "@fscrypto/domain/partner";
import { cn } from "~/utils/cn";

const cloudinaryBaseUrl = "https://res.cloudinary.com/dsr37ut2z/image/upload/v1722893427/assets/project-icons";

export const PartnerIcon = ({ partner, className }: { partner: Partner; className?: string }) => {
  return (
    <img
      src={`${cloudinaryBaseUrl}/${partner.slug}.png`}
      alt={`${partner.displayName} icon`}
      className={cn("ml-1 mr-2 size-6", className)}
    />
  );
};
